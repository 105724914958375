.pageInfoBlockWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
  background-color: #f8f9fc;
}
 
.mobilePageInfoBlockWrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: auto;
  align-items: center;
  justify-content: center;
  margin: 40px 0px;
  flex-wrap: wrap;
}

.pageInfoContainer {
  display: flex;
  width: 90%;
  height: 700px;
  background:#ffffff30;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  margin: 40px 8px; 
  box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);
}
  
.mobilePageInfoContainer {
  display: flex;
  width: 90%;
  height: 700px;
  background:#ffffff30;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  margin: 40px 8px; 
  box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);
}
 
.pageInfoCol {
  display: flex;
  width: 100%;
  height: 25%; 
  align-items: center;
  justify-content: center;
}
 
.mobilePageInfoCol {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 25%; 
  align-items: center;
  justify-content: center;
}
 
.pageInfoColSection {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  padding-left: 100px;
}
 
.mobilePageInfoColSection {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  align-items: flex-start;
  justify-content: center; 
}
 
.pageInfoColSectionTitle {
  display: flex; 
  width: 100%;
  height: 50%; 
  align-items: center;  
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 700;
  font-size: 25px;
  color: #292929; 
  font-style: normal; 
    letter-spacing: -.025rem;
}
 
.mobilePageInfoColSectionTitle {
  display: flex; 
  width: 100%;
  height: 50%; 
  align-items: center;  
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 700;
  font-size: 19px;
  color: #292929; 
  font-style: normal; 
    letter-spacing: -.025rem;
}
 
.pageInfoColSectionDesc {
  display: flex; 
  width: 100%;
  height: 50%;  
  border-bottom: 1px solid #c0c9da;
}

