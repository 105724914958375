.createpage_screen {
  background-color: #ffffff; 
} 
.createpage_wrapper {
  width: 100%; 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 135px;
  flex-wrap: wrap;
} 

 .createPageContainer {
    display: flex;
    width: 90%;
    margin:90px;
    padding: 40px;
    /* height: 1200px; */
    background: #ffffff; 
    flex-direction: column;
    border-radius: 4px;  
    box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);
 }

.croppActionContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* height: 600px;  */
}

.mobile_croppActionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 600px;  */
}

.croppActionLeftContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column; 
}

.croppActionRightContainer {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  align-items: center;
  justify-content: flex-start; 
  
}

.mobile_croppActionRightContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start; 
  
}

.btnAddFileContainer {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
}

.titleCropp {
  text-align: center;
  margin: 40px;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 14px;
  font-weight: 700;
  display: block; 
  text-transform: uppercase;
  color: #353d51;
}

.backImage {
  text-align: center;
  /* margin-top: 30px; */
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 14px;
  font-weight: 700;
  display: block; 
  text-transform: uppercase;
  color: #21abff;
  cursor: pointer;
}

.backImage:hover { 
  color: #5ec0fd; 
}

.titlePreviewCropp {
  text-align: center;
  margin-top: 30px;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 14px;
  font-weight: 700;
  display: block; 
  text-transform: uppercase;
  color: #353d51;
  margin-bottom: 200px;
}

.mobile_titlePreviewCropp {
  text-align: center;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 14px;
  font-weight: 700;
  display: block; 
  text-transform: uppercase;
  color: #353d51;
  margin: 40px;
}

.croppDoneBtnContainer {
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
}

.dateLiveyearsContainer {
  display: flex;
  width: 100%;
  height: 200px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dateLiveyearsTitleContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  
}

.dateBirthTitle {
  display: flex;
  width: 50%; 
  align-items: center;
  justify-content: center;
  flex-direction: column;
   text-align: center; 
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 14px;
  font-weight: 700;
  display: block; 
  text-transform: uppercase;
  color: #353d51; 
}

.dateDeadTitle {
  display: flex;
  width: 50%; 
  align-items: center;
  justify-content: center;
  flex-direction: column;
     text-align: center; 
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 14px;
  font-weight: 700;
  display: block; 
  text-transform: uppercase;
  color: #353d51; 
}

.texareaEpityTitle {
  display: flex;
  width: 50%; 
  align-items: center;
  justify-content: center;
  flex-direction: column;
     text-align: center; 
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 14px;
  font-weight: 700; 
  display: block; 
  /* margin-bottom: 30px; */
  text-transform: uppercase;
  color: #353d51; 
}

.dateLiveyearsPickerContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.saveBtnContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  margin-bottom: 80px;
}

.mobile_saveBtnContainer {
  display: flex;
  width: 100%;
  height: 150px;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  margin-bottom: 80px;
}
 
.inputFioContainer {
  display: grid;
  width: 90%;
  margin: 50px auto;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr; 
  align-items: flex-start;  
}
 
.mobileinputFioContainer {
  display: grid;
  width: 90%;
  margin: 50px auto;
  grid-gap: 2rem;
  grid-template-rows: 1fr 1fr; 
  align-items: flex-start;  
}
 
.epityTextAreaContainer {
  display: flex;
  width: 100%;
  margin-bottom: 100px;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
}
 
.mobile_epityTextAreaContainer {
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
}

.ant-input-textarea-show-count .ant-input-data-count {
  bottom: -42px;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper, .ant-upload-wrapper.ant-upload-picture-circle-wrapper {
  margin: 0px auto;
  width: 550px;
}

.mediaFilesContainer {
  display: flex;
  width: 100%;
  margin-bottom: 100px;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
}

.backgroundCreateContainer {
  display: flex;
  width: 100%;
  margin-bottom: 100px;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
}

.mobile_uploadwidth {
  display: flex;
  width: 100% !important; 
  padding-left: 7%;
}

.mobile_texareaEpityTitle {
  display: flex;
  width: 100%; 
  align-items: center;
  justify-content: center;
  flex-direction: column;
     text-align: center; 
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 14px;
  font-weight: 700; 
  display: block; 
  /* margin-bottom: 30px; */
  text-transform: uppercase;
  color: #353d51; 
  margin: 50px 0px;
}
.mobilecalendar { 
  inset: 700px auto auto 38px !important;
  width: 300px;
}
.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
  flex-direction: column;
}

/* input input__file */

.input__file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input__file + label {
  display: flex; 
  flex-direction: column;
  align-items: center; 
  border: 1px #363d51 solid;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 30px; 
  padding: 1.625rem 1.25rem;
}
  
.bioCreateSection {
  display: flex;
  width: 100%;
  margin-bottom: 100px;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  padding: 40px; 
  background-color: #fafafa; 
}
 
.mobile_bioCreateSection {
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  background-color: #fafafa; 
}

.bioAddBtn{ 
  width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 30px; 
    margin-top: 40px;
}

.bioIconBtn{ 
  font-size: 38px; 
  color: #6b7180;
}

.bioIconBtn:hover { 
  font-size: 40px; 
  color: #363d51;
}

.uploadContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 50px 0px;
}

.fileImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-top: 30px;
  margin-bottom: 50px;
}

.fileimg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: auto; 
}

.dateContainerLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; 
}
 
.datepicker {
  position: fixed !important; 
  bottom: 40% !important;
}
 
.datepicker-modal {
  position: fixed !important;  
}

.datepicker .datepicker-viewport {
  height: 350px;
  position: relative;
  overflow: hidden;
}

.datepicker .datepicker-content {
  flex-direction: row-reverse;
}