 
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: block;
  width: 100%;
  height: 100%; 
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #101010db; 
  transition: opacity .15s linear;
}

.modal-dialog {
  transition: transform .3s ease-out,-webkit-transform .3s ease-out;
  transform: none;  
  display: flex; 
  align-items: center;
  min-height: calc(100% - 3.5rem); 
  max-width: 900px;
  margin: 1.75rem auto;
}

/* .modal.fade .modal-dialog {
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out,-webkit-transform .3s ease-out;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
} */
 
.modal-content {
  /* width: 600px;  */
  overflow: hidden!important;
  position: relative; 
  display: flex; 
  flex-direction: column; 
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2); 
  outline: 0; 
  border-radius: 5px; 
  box-sizing: border-box;  
}
 
.modal button.close {
  position: absolute;
  right: 21px;
  top: 21px;
  padding: 0;
  background-color: transparent;
  border: 0; 
  appearance: none;
}
 
.nav-container {
  display: flex; 
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  padding-left: 3rem;
  padding-top: 3rem;
  padding-bottom: 1.5rem;
  margin-left: 1.5rem; 
  margin-right: 1.5rem; 
  margin-bottom: 1rem; 
  font-weight: 600;
  color: #8897a7; 
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; 
  line-height: 32px; 
  font-size: 28px; 
  cursor: pointer;
}
 
.mobilenav-container {
  display: flex;
  padding-left: 0;
  list-style: none;
  padding-left: 3rem;
  padding-top: 3rem;
  padding-bottom: 1.5rem;
  margin-left: 1.5rem; 
  font-weight: 600;
  cursor: pointer;
  align-items: center;
  margin-top: 22px;
  color: #8897a7;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  line-height: 23px;
  font-size: 17px;
  padding: 0px;
}

.nav-item { 
  padding-right: 3rem;
  /* margin-top: 0.5rem; */
  padding-left: 1rem;
  display: list-item;
  text-align: -webkit-match-parent; 
}

.mobilenav-item { 
  /* padding-right: 3rem;
  margin-top: 0.5rem;
  padding-left: 1rem; */
  display: list-item;
  text-align: -webkit-match-parent; 
}

.nav-link.active_tab { 
  font-weight: 700;
  color: #1e81d0; 
  padding-left: 2rem;  
  background-color: #fff;
  font-size: 30px;
}

.nav-link.mobile_active_tab { 
  font-weight: 700;
  color: #1e81d0; 
  padding-left: 2rem;  
  background-color: #fff;
  font-size: 20px;
}

.nav-link { 

}

.nav-link:hover {  
  color: #336f9d !important;
}
 
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
 
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
 

.nav-tabs, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border: none;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

 
.pl-5, .px-5 {
  padding-left: 3rem!important;
}
.pr-5, .px-5 {
  padding-right: 3rem!important;
}
.pt-5, .py-5 {
  padding-top: 3rem!important;
}
.pb-4, .py-4 {
  padding-bottom: 1.5rem!important;
}
.ml-4, .mx-4 {
  margin-left: 1.5rem!important;
}
.mr-4, .mx-4 {
  margin-right: 1.5rem!important;
}
.mb-3, .my-3 {
  margin-bottom: 1rem!important;
}
.mt-3, .my-3 {
  margin-top: 1rem!important;
}

dl, ol, ul {
  margin-top: 0;
}
address, dl, ol, ul {
  margin-bottom: 1rem;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}


.login-tab {
  border-right: 1px solid #d8d8d8;
}
.pr-5, .px-5 {
  padding-right: 3rem!important;
}
.pl-3, .px-3 {
  padding-left: 1rem!important;
}
.mt-2, .my-2 {
  margin-top: 0.5rem!important;
}
li {
  list-style-type: none;
}
 
.f-28 {
  font-size: 28px;
}
.p-0 {
  padding: 0!important;
}

.lh-32 {
  line-height: 32px;
}
.f-28 {
  font-size: 28px;
}

.pl-5, .px-5 {
  padding-left: 3rem!important;
}
.p-0 {
  padding: 0!important;
}


.background-grey {
  background-color: #f2f2f2;
}

.p-5 {
  padding: 3rem!important;
}

.tab-content>.active {
  display: block;
}

.tab-content>.tab-pane {
  display: block;
}

form {
  display: block;
  margin-top: 0em;
}
.form-group {
  margin-bottom: 2.5rem;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.invalid-feedback {
  font-weight: 600;
  font-size: 14px;
  color: #fa3030;
  line-height: 19px;
}

.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.form-control.is-invalid~.invalid-feedback, .form-control.is-invalid~.invalid-tooltip, .was-validated .form-control:invalid~.invalid-feedback, .was-validated .form-control:invalid~.invalid-tooltip {
  display: block;
}

.custom-password-control {
  position: relative;
}

.tab-content form input[type=email], .tab-content form input[type=number], .tab-content form input[type=tel], .tab-content form input[type=text] {
  height: 61px;
  padding: 20px 23px;
  font-size: 14px;
  border: 1px solid #d7d7d7;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  -khtml-border-radius: 8px;
  border-radius: 8px;
}
.tab-content form input[type=email], .tab-content form input[type=number], .tab-content form input[type=password], .tab-content form input[type=tel], .tab-content form input[type=text] {
  height: 61px;
  padding: 20px 23px;
  font-size: 14px;
  border: 1px solid #d7d7d7;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  -khtml-border-radius: 8px;
  border-radius: 8px;
}
.form-control-lg {
  font-size: 1.25rem;
  border-radius: 8px;
  border: 1px solid #d7d7d7;
  line-height: 48px;
  padding: 20px 23px;
  height: 48px;
}
.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
 

.text-right {
  text-align: right!important;
}

.clearfix:after {
  display: block;
  clear: both;
  content: "";
}

label {
  font-weight: 600; 
  font-size: 14px;
  color: #0C0D0D;
  margin-bottom: 16px;
  line-height: 19px;
  display: inline-block;
  margin-bottom: 20px !important;
}
 

.lh-20 {
  line-height: 20px;
}

.f-18 {
  font-size: 18px;
}
.font-weight-bold {
  font-weight: 700!important;
}
.mb-5, .my-5 {
  margin-bottom: 3rem!important;
}
.d-block {
  display: block!important;
}
.p-5 {
  padding: 3rem!important;
}
.modal-open {
  overflow-x: hidden;
  overflow-y: auto;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center; 
}
 

.fade:not(.show) {
  opacity: 0;
}
 

*, :after, :before {
  box-sizing: inherit;
  outline: none!important;
}
 
.create__column {
  border: 1px solid rgba(192,201,218,.31);
  border-radius: 4px;
  box-shadow: 0 40px 40px hsla(0,0%,74.5%,.16);
}

title {
  height: 96px;
}
 
.create__row-value-inner {
  width: 12px;
  height: 12px;
}

.create__row-value-inner {
  display: block;
  border-radius: 50%;
  background: rgb(18 162 222 / 65%);
}
 

.create__bottom-price {
  font-size: 2.2rem;
  font-weight: 800;
  display: block;
  text-align: center;
  color: #292929;
  margin-bottom: 2rem;
}

.create__bottom-price_balance_mobile {
  display: flex !important;
  align-items: center;
  justify-self: center;
  font-size: 1.0rem;
  font-weight: 800;
  display: block;
  text-align: center;
  color: #292929;
  margin-bottom: 2rem;
}
 
.titlePage {
  margin-bottom: 20px;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 600;
  color: #353d51;
}
 
.create__table {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
}
 
.btnTableContainer {
  display: flex;
  width: 100%;
  height: 70px;  
  flex-direction: row;
}

.btnCol {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.3%;
  flex-direction: column;
  height: 100%;
}

.succesRegistration {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-top: 40px;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 600;
  color: #2a8b0b;
}

.errorRegistration {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-top: 40px;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 600;
  color: #dc3545;
}

.errorEnter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-top: 40px;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 600;
  color: #8b200b;
  text-align: center;
}

.succesEnter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-top: 40px;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 600;
  color: #0b8b2a;
  text-align: center;
}
 
.mobile_tabcontent {
  padding-top: 20px;
}

.mobile_float-right {
  font-size: 15px;
  color: #349bfe;
}

.mobile_confirm_order {
  line-height: 1.5;
  justify-content: center;
  display: flex;
  width: 90%;
  height: auto;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  font-family: sans-serif;
  color: #676767;
  margin-bottom: 40px;
}


.interactiveBalansContainer {
  display: flex;
  width: 90%; 
  flex-direction: column; 
  padding: 20px;
  border-top: 1px solid rgb(205 205 205);
}

.titleInteractiveOutContainer {
  display: flex;
  align-items: center; 
  width: 90%; 
  flex-direction: row; 
  padding-top: 20px;
  padding-bottom: 20px; 
}
 
.successtxt {
  width: 100%;
  text-align: center;
  color: #28a512;
  font-size: 20px; 
  font-weight: 500; 
}

.mobile_successtxt {
  width: 100%;
  text-align: center;
  color: #28a512;
  font-size: 15px; 
  font-weight: 500; 
}
.errortxt {
  width: 100%;
  text-align: center;
  color: #b12222;
  font-size: 20px; 
  font-weight: 500; 
}

.mobile_errortxt {
  width: 100%;
  text-align: center;
  color: #b12222;
  font-size: 15px; 
  font-weight: 500; 
}
.mobile_titleInteractiveOutContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 240px; 
}
.titleInteractiveBalans {
  color: #333333;
  font-size: 20px;
  padding-left: 12px;
}

.interactiveInfo {
  color: #b12222;
  font-size: 20px;
  /* padding-left: 12px; */
  font-weight: 500;
  margin-bottom: 20px;
}
.mobile_interactiveInfo {
  color: #b12222;
  font-size: 16px;
  padding-left: 12px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}

.input_component_item {
  margin-bottom: 20px;
  width: 50%;
  display: flex; 
  height: 35px;
  align-items: center;
  border-radius: 5px;
  padding-left: 20px;
  border-radius: 6px;
  border: none;
  padding-left: 8px;
  color: #4a5aaf;
  box-shadow: 1px 1px 5px #00000029;
}

.mobile_input_component_item {
  margin-bottom: 20px;
  width: 100%;
  display: flex; 
  height: 35px;
  align-items: center;
  border-radius: 5px;
  padding-left: 20px;
  border-radius: 6px;
  border: none;
  padding-left: 8px;
  color: #4a5aaf;
  box-shadow: 1px 1px 5px #00000029;
}