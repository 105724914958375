.footer_container {
  display:flex;flex-direction:column;width:100%;background-color:#333;align-items:center;justify-content:center;
}
.footer_up {
  display:flex;flex-direction:row;width:100%;background-color:#2a2f3a;align-items:flex-start;justify-content:center;padding-top:50px;z-index:0
}
  .footer_down {
    display:flex;flex-direction:column;width:100%;background-color:#2a2f3a;align-items:center;justify-content:center;z-index:1
  }
  .visa_container img{
    margin-left:20px
  }
  .coperait_txt{
    user-select:none;color:#999;padding:5px 0;font-size:14px;
  }
  .footer_txt{
    cursor:pointer;user-select:none;text-decoration: none;margin:0 36px 18px 0;font-family:'Montserrat-Bold';font-size:17px;font-weight:500;font-stretch:normal;font-style:normal;line-height:normal;letter-spacing:.79px;color:#f8f5f0
  }
  .footer_txt:hover{
    color:#6f788e
  }
  .footer_down::before{
    content:"";display:block;width:80%;height:1px;background:rgba(248,245,240,.18);margin:50px auto
  }
  .footer_desription_section_right{
    display: flex;
    padding-top: 40px;
    align-self:flex-start
  }
  .footer_desription_section_right a{
    text-decoration: none;
    user-select: none;
    outline:none;
    -webkit-tap-highlight-color: transparent;
  }
  .footer_desription_section_left a{
    text-decoration: none;
    user-select: none;
    outline:none;
    -webkit-tap-highlight-color: transparent;
  }
  .footer_desription_section_left{
    display: flex;
    padding-top: 40px;
  }
  .visa_container{
    margin-bottom:0px
  }
  .footer_social_section{
    margin-left:0px
  }
  .footer_logo_section{
    padding-right:0px
  } 

  .iframe {
    width: 460px;
    height: 230px;
  }

  .mobile-iframe {
    width: 300px;
    height: 220px;
    margin-bottom: 10px;
  }
  .mobile-footer_txt{
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    text-decoration: none;
    margin: 0 7px 15px 0;
    font-family: 'Montserrat-Bold';
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: .79px;
    color: #9f9f9f;
    color: #999;
  }
  .mobile-coperait_txt{
    user-select:none;color:#999;padding:4px 0;font-size: 11px;
  }

.footer_button_container {
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: space-evenly;
}

.mobileFooter_button_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100px; */
  justify-content: center;
  align-items: center;
}

.container_visa_coperait {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
}

.mobile-container_visa_coperait{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  flex-direction: column-reverse;
}

.mobile-visa_container {
  margin: 20px 0px;
}

@media ( width <= 1100px ) { 
  .iframe {
    width: 320px;
    height: 200px;
  }

  .footer_up {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer_desription_section_right{
    width: 100%;
    align-content: center;
    justify-content: center;
    display: flex;
  }
  .footer_desription_section_left{
    width: 100%;
    align-content: center;
    justify-content: center;
    display: flex;
  }
}
@media ( width >= 707px ) { 
  .footer_container {
    min-width: 745px;
  }
}

.mobile-footer_downs {
  display:flex;flex-direction:column;width:100%;background-color:#2a2f3a;align-items:center;justify-content:center;z-index:1
}