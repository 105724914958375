
.backgroundContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 280px;
  height: 450px; 
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 30px;
} 

.backgroundItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: center; 
  align-items: center; 
} 

.mobileBackgroundItemContainer {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
} 

.backgroundItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  height: 90%;
  background-color: white; 
  box-shadow: 1px 1px 12px rgb(0 0 0 / 30%);
  border-radius: 10px; 
  align-items: center; 
  margin-bottom: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; 
}