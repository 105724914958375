.titleWrapper {
  display: flex;
  width: auto;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}
.mobileTitleWrapper {
  display: flex;
  width: auto;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 25px;
  color: white;
  margin: 50px 0px 0px;
  z-index: 2;
  word-wrap: break-word;
  text-align: center;
  line-height: 1.3;
}

.titleContainer {
  display: flex;
  width: auto;
  height: auto;
} 

.titleText {
  text-align: center;
  font-weight: 600 ;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol ;
} 

.underline {
  display: flex;
  height: 2px;
  width: 40px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  margin-top: 30px;
}

