  .miniPageBlockContainer {
    display: flex;
    position: relative;
    width: 90%;
    height: 500px;
    background: #ffffff; 
    flex-direction: column;
    border-radius: 4px;
    margin: 30px auto;
    box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);
}
  .mobileMiniPageBlockContainer {
    display: flex;
    position: relative;
    width: 90%;
    height: 350px;
    background: #ffffff;
    flex-direction: column;
    border-radius: 4px;
    margin: 30px auto;
    box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);
}
  
.mobilePageInfoContainer {
  display: flex;
  width: 90%;
  height: 500px;
  background: #ffffff; 
  flex-direction: column;
  border-radius: 4px;
  margin: 30px auto;
  box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);
}
 

.topMiniPageBlock {
  display: flex; 
  width: 100%;
  height: 50px; 
  border-bottom: 1px solid rgba(192,201,218,.31);
  background: #f0f3f9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
 justify-content: space-between;
}

.mobileTopMiniPageBlock {
  display: flex;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid rgba(192,201,218,.31);
  background: #f0f3f9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
}

.bottomMiniPageBlock {
  display: flex; 
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;  
}

.titleTopMiniPage {
  display: flex;
  font-size: 12px;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 700;
  color: #353d51;
  text-transform: uppercase;
  line-height: 1.7;
  align-items: center;
  margin-left: 20px;
}

.centerMiniBlock {
  display: flex; 
  flex-direction: column;
  width: 90%;
  height: 88%;
  align-items: center;
  justify-content: center;  
}

.mobilecenterMiniBlock {
  display: flex; 
  flex-direction: column;
  width: 90%;
  height: 95%;
  align-items: center;
  justify-content: center;  
}

.topCenterMiniBlock {
  display: flex; 
  flex-direction: column;
  width: 100%;
  height: 80%;
  align-items: center;
  justify-content: center;  
  border-bottom: 1px solid #c0c9da;
}

.topCenterImgBlock {
  display: flex; 
  width: 40%;
  height: 100%;
  align-items: center;
  justify-content: center;  
}

.topCenterImgBlock img{
  display: flex;
  width: auto;
  /* width: 170px; */
  height: 150px;
  border-radius: 5px;
}

.topCenterDateBlock {
  display: flex; 
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;   
  font-size: 12px;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 700;
  color: #353d51;
  text-transform: uppercase;
  line-height: 1.7; 
  margin-left: 20px;
}

.topTitleBlock { 
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 20px;
  font-weight: 600;
  display: block;
  margin-bottom: 1rem;
}

.mobiletopTitleBlock { 
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 14px;
  font-weight: 600;
  display: block; 
}

.topDataBlock {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-family: HelveticaNeue,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4; 
  text-align: left;
  word-break: break-word; 
}

.bottomCenterMiniBlock {
  display: flex; 
  width: 100%;
  height: 20%;
  align-items: center;
  justify-content: center;  
} 

.emptyMiniPageBlock {
  display: flex; 
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;  
}

.mobileEmptyMiniPageBlock {
  display: flex; 
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;  
}

.emptyCenterMiniBlock
{
  display: flex; 
  width: 100%;
  height: 70%;
  align-items: center;
  justify-content: center;  
}

.mobileEmptyCenterMiniBlock
{
  display: flex; 
  width: 100%;
  height: 70%;
  align-items: center;
  justify-content: center;  
}

.bottomBtnBlock {
  display: flex; 
  width: 100%;
  margin-top: 30px;
  align-items: center;
  justify-content: space-evenly;  
  flex-direction: row;
}

.mobilebottomBtnBlock {
  display: flex; 
  width: 100%; 
  align-items: center;
  justify-content: space-evenly;  
  flex-direction: row;
}


.deletedContainer {
  display: flex;
    width: 50px;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.delimg { 
  width: 20px;
  height: 20px; 
}

.mobiledelimg { 
  width: 18px;
  height: 18px; 
}

.delclickc { 
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.disabled_cart { 
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3;
  background-color: #00000091;
  border-radius: 5px;
}