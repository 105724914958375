.tableColWrapper {
  display: flex;
  width: 33%; 
  height: auto; 
  align-items: center;
  justify-content: center;  
}
 
.mobileTableColWrapper {
  display: flex;
  width: 33%; 
  height: auto; 
  align-items: center;
  justify-content: center;  
  font-size: 15px;
}
 

.serviceContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.serviceTitle {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 800;
  display: block;
  padding-top: 16px;
  padding-left: 5px;
  padding-bottom: 10px;
  color: rgb(54 61 81);
  border-bottom: 2px solid rgba(53,61,81,.32);
  height: 50px;
}

.serviceCol {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    display: flex;
    align-items: center;
    padding: 5px;
    color: #000;
    height: 53px;
    word-break: break-word;
    border-bottom: 1px solid #e2e1e1;
}

.serviceCol:last-child {
  border-bottom: 2px solid rgba(53,61,81,.32);
}

.dotsContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 20px;
}

.dotsTitle {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 700; 
  padding: 30px auto;
  color: rgb(54 61 81);
  border-bottom: 2px solid rgba(53,61,81,.32);
  height: 70px; 
  background-color: #fff;
  border-left: 1px solid #e2e1e1;
  border-top: 1px solid #e2e1e1;
  border-right: 1px solid #e2e1e1;
}

.dotsCol {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    display: flex;
    align-items: center;
    padding: 5px;
    background-color: #fff;
    height: 53px;
    word-break: break-word;
    border-bottom: 1px solid #e2e1e1;
    border-left: 1px solid #e2e1e1;
}

.dotsCol:last-child {
  border-bottom: 2px solid rgba(53,61,81,.32);
}