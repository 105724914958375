.itemWrapper {
  display: flex; 
  width: 100%;
  height: 770px;
  margin-top: 20px;
  background-color: #f8f9fc; 
}

.mobileItemWrapper {
  display: flex; 
  width: 100%;
  height: 600px;
  margin-top: 20px;
  background-color: #f8f9fc; 
}

.rowItemRig {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 33%;
  height: 100%;
}

.wrapperVersion {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin: 10px 0px;
}

.colInfoBlock {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: start;
  align-items: flex-start;
  box-sizing: border-box;
  padding-left: 30px;
  padding-top: 30px;
}

.txt_maintxt {
  height: auto;
  font-size: 33px;
  font-weight: 600;
  font-family: system-ui;
  color: #444;
  padding: 0px 0px 20px 22px;
  border-bottom: 2px solid #ea402f;
  font-family: 'Roboto', sans-serif;
}

.txt_main_subtxt {
  width: 80%;
  font-size: 14px;
  color: #222;
  padding-top: 20px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  border-top: 2px solid #ea402f;
}

.mobile-txt_main_subtxt {
  width: 80%;
  font-size: 12px;
  color: #222;
  padding-top: 10px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  border-top: 2px solid #ea402f;
}

.wrapper_maintxt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 90%;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  margin: 190px auto;
  position: relative;
  box-sizing: border-box;
}

.mobile-wrapper_maintxt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column; 
  height: 70%;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  margin-top: 130px;
  position: relative;
}

.mobile-wrapper_maintxt .titleText {
  text-align: center;
}

.wrapper_img_mainlogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
}

.img_mainlogo {
  width: 120%;
  height: 120%;
  padding-top: 200px;
}

.colTimerBlock {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  position: absolute;
  /* bottom: 20px; */
}

.mobileColTimerBlock {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column-reverse;
  position: absolute;
  /* bottom: 20px; */
}

.wrapperTimer {
  display: flex;
  width: 50%;
  height: 40px;
  justify-content: center;
  align-items: flex-end;
}

.wrapperTimer h1{
  font-size: 30px;
}
.timerTxt {
  color: #222;
  font-size: 23px;
  padding: 8px;
}

.mobile-txtTimer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 130px;  
}

.mobileSpantxt { 
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  color: #353d51;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  line-height: 1.3;
}

.colButtonBlock {
  display: flex;
  width: 100%;
  height: 30%;
  justify-content: flex-start;
  align-items: center;
  padding-left: 170px;
}
.wrapperButton {
  display: flex;
  width: 150px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: #ea402f;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  padding: 0 22px;
  font-size: 14px;
  line-height: 40px;
  color: #FFF;
  font-weight: 700;
  border-radius: 3px;
  margin-top: 30px;
}

.wrapperButton:hover {
  background-color: rgb(72, 72, 240);
}

.txtButton {
  font-size: 15px;
  font-weight: 600;
  font-family: system-ui;
  color: white;
}

.txtTimer {  
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 200px;
  line-height: 1.2; 
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  color: #353d51; 
}

.spantxt {
  font-size: 2.5rem;
  text-transform: uppercase;  
  font-weight: 600; 
}

.spansubtxt {
  font-size: 1.1rem;  
  font-weight: 600; 
}

.mobileSpansubtxt {
  margin-top: 20px;
  font-size: 16px;  
  font-weight: 600; 
  line-height: 1.3;
  text-align: center;
}
 
.infoLeftRow {
  display: flex;
  width: 58%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}
 
.mobileInfoLeftRow {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}
 
.infoRightRow {
  display: flex;
  width: 38%;
}
 
.mobileInfoRightRow {
  display: flex;
  width: 60%;
}
 
.imglogoqr {
  display: flex;
  width: 100%;
}
 
.lineContainer {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 15px;
  font-weight: 600;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}
 
.mobileLineContainer {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 12px;
  font-weight: 600;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}

@media (600px >= width <= 780px ) { 
  .mobile-wrapper_maintxt {
    width: 40%;
    margin-left: 210px;
    margin-top: 250px;
  }
  .colInfoBlock {
    padding-left: 30px; 
  }
}

@media ( width <= 600px ) { 
  .itemWrapper {
    background-position: -680px 140px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

