.pageBioBlockWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
  background-color: #f8f9fc;
}
 
.mobilePageBioBlockWrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: auto;
  align-items: center;
  justify-content: center;
  margin: 40px 0px;
  flex-wrap: wrap;
}

.pageBioContainer {
  display: flex;
  width: 90%;
  padding: 40px;
  /* height: 700px; */
  background: #ffffff30;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  margin: 40px 8px; 
  box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);
}

.mobilePageBioContainer {
  display: flex;
  width: 90%;
  padding: 10px; 
  background: #ffffff30;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  margin: 40px 8px;
  box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);
}
 
 
.onePhoto {
  width: 40%;
  height: 100%; 
  margin: 0 auto;
}

.twoPhoto {
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  padding-left: 50px;
}

.bioPhoto {
  display: flex;
  width: 100%;
  height: 100%;
}

.bioPhotoDesc {
  display: flex;
  width: 100%;
  justify-content: end;
  margin: 20px auto;
}

.bioLineGray {
  display: flex;
  width: 90%;
  height: 1px;
  margin-top: 20px;
  background-color: #c0c9da;
  align-self: center;
}

.bioSummarySection {
  display: flex;
  max-width: 720px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  white-space: pre-line;
  margin: 10px auto; 
  font-family: HelveticaNeue,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 17px;
  font-weight: 400;
  /* line-height: 1.2;    */
  word-break: break-word;
  color: #535866; 
}