.dataLineContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 90px; 
  align-items: center;
  justify-content: center;  
}

.mobileDataLineContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 90px; 
  align-items: center;
  justify-content: center;  
}
  
.lineGray {
  display: flex;
  width: 30%;
  height: 2px;
  align-items: center;
  justify-content: center; 
  background-color: #c0c9da;
}
  
.mobileDataLlineGray {
  display: none;
  width:5%;
  height: 2px;
  align-items: center;
  justify-content: center; 
  background-color: #c0c9da;
}

.lineBlack {
  display: flex;
  width: 15%;
  height: 2px;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
  box-sizing: border-box;
  background-color: black;
}

.mobileLineBlack {
  display: flex;
  width: 8%;
  height: 2px;
  align-items: center;
  justify-content: center; 
  box-sizing: border-box;
  background-color: black;
}

.birthDateContainer {
  display: flex;
  width: 15%;
  height: 100%; 
  align-items: center;
  justify-content: center; 
  font-size: 18px;
}

.mobileBirthDateContainer {
  display: flex;
  width: 10%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  word-wrap: break-word;
  text-align: center;
}

.deathDateContainer {
  display: flex;
  width: 15%;
  height: 100%; 
  align-items: center;
  justify-content: center; 
  font-size: 18px;
}

.mobileDeathDateContainer {
  display: flex;
  width: 10%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  word-wrap: break-word;
  text-align: center;
}

.yearsContainer {
  display: flex;
  width: 30%;
  height: 100%;
  align-items: center;
  justify-content: center; 
  flex-direction: column;
  box-sizing: border-box;

  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 800; 
  font-size: 15px;
    line-height: 20px;
    color: #303030;
  word-wrap: break-word; 
}

.mobileYearsContainer {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center; 
  flex-direction: column;
  box-sizing: border-box;

  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 800; 
  font-size: 15px;
    line-height: 20px;
    color: #303030;
  word-wrap: break-word; 
}

.yearTop {
  display: flex;
  width: 90%;
  height: 70%;
  align-items: center;
  justify-content: center; 
  flex-direction: row;  
  font-size: 35px;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 600; 
  color: #292929; 
  word-wrap: break-word; 
}

.mobileYearTop {
  display: flex;
  width: 90%;
  height: 25%;
  align-items: center;
  justify-content: center; 
  flex-direction: row;  
  font-size: 28px;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 600; 
  color: #292929; 
  word-wrap: break-word; 
}

.yearBottom {
  display: flex;
  width: 100%;
  height: 30%;
  align-items: center;
  justify-content: center;   
}

.mobileYearBottom {
  display: flex;
  width: 100%;
  height: 30%;
  align-items: center;
  justify-content: center;
  font-size: 10px;   
}

.linemonitor {
  width: 16%;
  height: 150px;

}

.mobileDataLinemonitor {
  width: 10%;
  height: 150px;

}