.login_screen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.filterContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}

.mobileFilterContainer {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f5faff;
}

.titleFilters {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: #333;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  background-color: #f5faff;
  }

  .titleMonument {
    font-size: 30px;
    color: #333;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
  }

  .subTitleFilter {
    width: 100%;
    height:120px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    background-color: #f5faff;
  }

  .mobileSubTitleFilter {
    width: 100%;
    height:360px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f5faff;
  }

  .backgroundFilter {
    display: flex;
    background-color: #f5faff;
    justify-content: center;
    align-items: center;
  }

  .answerContainer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    flex-direction: column;
  }

  .answerBtnWrapper {
    display: flex;
    width: 100%;
    /* height: 100px; */
    justify-content: center;
    align-items: center;
    background-color: #fff;
    flex-direction: column;
  }

  .answerAbout {
    line-height: 1.5;
    display: flex;
    /* width: 50%; */
    height: auto;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    font-family: sans-serif;
    color: #676767;
  }

  .mobile_answerAbout {
    line-height: 1.5;
    display: flex;
    width: 90%;
    height: auto;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    font-family: sans-serif;
    color: #676767;
  }

  .popup_textarea{
    width: 50%;
    height: 200px;
    margin-top: 30px;
    margin-bottom: 10px;
    border-radius: 6px;
    border-color: #c0c9da;
    resize: none;
    color: #363d51;
    padding: 20px;
    font-size: 1.2em;
    font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  }
  .popup_textarea::placeholder{
    color:#363d51;font-size:1em;font-family:Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  }
  .popup_textarea:focus{
    outline:none;box-shadow:0 0 16px #c0c9da
  }
  .popup_input:focus{
    outline:none;box-shadow:0 0 16px #c0c9da
  }
  .popup_input{
    border:1px #c0c9da solid;width:50%;height:50px;border-radius:4px;border-color:#c0c9da;resize:none;color:#363d51;padding:15px;font-size:1.2em;font-family:Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;margin-bottom: 30px;
  }
  .popup_input:focus{
    border:1px #c0c9da solid;width:50%;height:50px;border-radius:4px;border-color:#c0c9da;resize:none;color:#363d51;padding:15px;font-size:1.2em;font-family:Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  }

  .mobile_popup_textarea{ 
    width: 90%;
    height: 260px !important;
    border-radius: 6px;
    border-color: #c0c9da;
    resize: none;
    color: #363d51;
    padding: 20px;
    font-size: 15px;
    font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
    line-height: 1.3;
  }
  .mobile_popup_textarea::placeholder{
    color:#363d51;font-size:1em;font-family:Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  }
  .mobile_popup_textarea:focus{
    outline:none;box-shadow:0 0 16px #c0c9da
  }
  .mobile_popup_input:focus{
    outline:none;box-shadow:0 0 16px #c0c9da
  }
  .mobile_popup_input{
    border:1px #c0c9da solid;width:100%;height:50px;border-radius:4px;border-color:#c0c9da;resize:none;color:#363d51;padding:15px;font-size:1.2em;font-family:Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;margin-bottom: 30px;
  }
  .mobile_popup_input:focus{
    border:1px #c0c9da solid;width:100%;height:50px;border-radius:4px;border-color:#c0c9da;resize:none;color:#363d51;padding:15px;font-size:1.2em;font-family:Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  }

  .formWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    box-sizing: border-box;
  }

  .mobileItem {
    width: 270px !important;
  }

  .error_input{border:2px solid #e4292b!important}

  @media (width <= 1090px ) { 
    .mobileTitle .titleText {
      font-size: 30px;
    } 
  }


.mobileresultMssg {
  text-align: center;
}