
.bioSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
}
 

.bioInnerSection {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 70px auto;
}

.mobile_bioInnerSection {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 20px auto;
}

.bioInnerTitle {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  color: #292929;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 600; 
  font-size: 30px;
}

.mobile_bioInnerTitle {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  color: #292929;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 600; 
  font-size: 23px;
}

.bioInnerDescr {
  display: flex;
  width: 100%;
  white-space: pre-wrap;
  font-family: HelveticaNeue,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.2;  
  text-align: left;
  word-break: break-word; 
  color: #535866;  
}

.mobile_bioPhotoSection  {
  width: 100% !important;
}
  
.twoPhoto .bioPhotoContainer {
  width: 40%;
}

.twoPhoto .mobile_bioPhotoContainer {
  width: 80%;
}

.onePhoto .mobile_bioPhotoContainer {
  align-self: center;
  width: 80%;
}

.bioPhotoContainer {
  display: flex;
  flex-direction: column;   
}
