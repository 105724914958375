.container_textarea {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;
}

.lable_textarea {
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 15px;
  font-weight: 600; 
  text-transform: uppercase;
  color: #6b707f;
}

.mobile_lable_textarea {
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 15px;
  font-weight: 600; 
  text-transform: uppercase;
  color: #6b707f;
  margin-top: 10px;
}

.lable_container {
  display: flex;
  width: 50%;
  height: auto;
  justify-content: end;
}

.mobile_lable_container {
  display: flex;
  width: 86%;
  height: auto;
  justify-content: end;
}

