.examplePageBlockWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
  background-color: #f8f9fc;
}

/* .workOrderItemWrapper {
  display: flex;
  width: 85%;
  height: auto;
  align-items: center;
  justify-content: center;
  margin: 40px 0px;
  flex-wrap: wrap;
} */

.mobileExamplePageBlockWrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: auto;
  align-items: center;
  justify-content: center;
  margin: 40px 0px;
  flex-wrap: wrap;
}

.examplePageBlockBtnWrapper {
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;
}