.plusItemWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  background: url('../../assets/images/fon.png') left 50% top 50% / cover no-repeat fixed !important;
}

.actionCardImg {
  width: 100%;
  height: 100%;
}

.layout {
  width: 100%;
  height:100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6)!important;
}

.plusLeftBlock {
  display: flex;
  width: 40%;
  height: 100%;
  /* background-color: #192628; */
  justify-content: center;
  padding: 30px 0px;
  align-items: center;
  box-sizing: border-box;
}


.plusRightBlock {
  display: flex;
  width: 80%;
  height: 100%;
  /* background-color: rgb(236, 226, 213); */
  justify-content: space-evenly;
  padding: 30px 0px;
  box-sizing: border-box;
}

.mobilePlusRightBlock {
  display: flex;
  width: 80%;
  height: 100%;
  /* background-color: rgb(236, 226, 213); */
  justify-content: space-evenly;
  padding: 30px 0px;
  box-sizing: border-box;
}


.mobilePlusRightBlock {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  /* background-color: rgb(236, 226, 213); */
  justify-content: space-evenly;
  padding: 30px 0px;
  box-sizing: border-box;
}

.plusItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  width: 26%;
  min-width: 325px;
  height: 370px;
  padding: 30px; 
  border-radius: 10px; 
  background-color: white; 
  color: #133e60; 
}

.mobilePlusItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  width: 100%;
  height: 320px;
  padding: 30px; 
  border-radius: 10px; 
  background-color: white; 
  color: #133e60; 
  margin-bottom: 20px; 
}

.containerActions {
  display: flex; 
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
}

.actionCardImg {
  width: 90%;
  height: 90%;
}

.containerActionTxt {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20%; 
  padding-top: 4px;
  font-size: 19px;
  line-height: 1.3;
  word-wrap: break-word;
}







.titlePlus {
  font-size: 30px;
  color: white;
  font-weight: 600 ;
  font-family: 'Roboto', sans-serif ;
  z-index: 9;
}

@media (width <= 1150px ) { 
  .plusItem {
    font-size: 20px;
  } 
}

@media (width <= 990px ) { 
  .plusRightBlock {
    width: 80%;
  }
}

