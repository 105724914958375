.headerWrapper {
  display: flex;
  flex-direction: column;
  background-color: #2a2f3a;
  width: 100%;
  height: auto;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  position: fixed; 
  z-index: 99;
  min-width: 740px;
}

.mobileHeaderWrapper {
  display: flex;
  flex-direction: column;
  background-color: #2a2f3a;
  width: 100%;
  height: 100px;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  position: fixed; 
  z-index: 100;
}
 
.bottomHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 130px;
  display: flex;
  background-color: #2a2f3a;
}
 
.mobileBottomHeader {
  width: 100%;
  height: auto;
  display: flex;
  background-color: #2a2f3a;
  margin-top: 20px;
  flex-direction: column;
}

.subLogo {
  text-align: center;
  color: #d8deed; 
}

.topHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.catigories {
  display: flex;
  padding-left: 100px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width:100%;
  height: 100px;
}

.catigories a {
  text-decoration: none;
}

.logo {
  color: #f5faff;
  margin-bottom: 8px;
  font-size: 32px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif !important;
}

.mobileLogo {
  color: #f5faff;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 5px;
  font-family: 'Roboto', sans-serif !important;
}

.tab {
  display: flex;
  /* width: 110px;
  height: 28px; */
  justify-content: center;
  align-items: center;
  color: #f8f5f0;
  /* color: rgb(255, 255, 255); */
  margin-right: 10px; 
  font-size: 16px;
  text-decoration: none;
  user-select: none;
  outline:none;
  -webkit-tap-highlight-color: transparent; 

  padding: 1rem 1.1rem;
  outline: none;
  border: none;
  overflow: hidden;
  border-radius: 4px;
  background: #464e62;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.tab:hover {
  color: rgb(209, 225, 255);
  background: #6f788e !important;
  /* border-bottom: 1px solid #464e62; */
  /* border-bottom: 1px solid #c5c7da; */
}

.tabMobile {
  display: flex;
  width: auto;
  height: 21px;
  justify-content: flex-start;
  align-items: center;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 16px;
  margin: 30px 0px;
  text-decoration: none;
  user-select: none;
  outline:none;
  -webkit-tap-highlight-color: transparent;

}

.active {
  color: rgb(209, 225, 255);
  background: #2d3445 !important; 
  padding: 20px;
}

.textLogoContainer {
  display: flex;
  align-items: center;
  width: 240px;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.mobiletextLogoContainer {
  display: flex;
  align-items: center;
  width: auto;
  margin-right: 5px;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.lableContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: 100px;
}

.mobileLableContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 10px 0px 10px 60px;
}

.mobileContainerLi {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
}

.mobile-header-extras {
  font-family: 'Roboto', sans-serif !important;
}

.header-extras li:first-child {
  margin-left: 0;
}

.header-extras li {
  float: left;
  margin-left: 100px;
  height: 40px;
  list-style: none;
}

.header-extras {
  width: auto;
  margin-right: 80px;
}

.mobile-header-extras li {
  height: 40px;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.header-extras li .he-text {
  float: right;
  padding-left: 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.43;
}

.mobile-header-extras li .he-text {
  float: right; 
  font-weight: bold;
  font-size: 11px;
  line-height: 1.43;
}

.header-extras li .he-text span {
  display: block;
  font-weight: 400;
}

.i-plain {
  width: 33px;
  height: 33px; 
}

.mobile-i-plain {
  width: 20px;
  height: 20px;
  margin: 5px;
}

.nomargin {
  margin: 0 !important;
}

.text-muted {
  color: #ffffff !important;
}

.cart {
  padding-right: 80px;
  cursor:'pointer';
}

.cartMobile {
  position: absolute;
  right: 25px;
  top: 25px;
}

.i-local {
  width: 42px;
  height: 42px;
}

.qrbtncontainer {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

@media ( width <= 1365px ) { 
  .logo { 
    font-size: 35px; 
  }

  .subLogo {
    font-size: 12px;
  }

  .i-local {
    width: 25px;
    height: 25px;
  }

  .i-plain {
    width: 21px;
    height: 21px; 
  }

  .header-extras li {
    height: auto;
    margin-left: 5px;
  }

  .header-extras {
    width: 61%;
    margin-right: 0px;
  }
}

.profiletab {
  display: block;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  color: #353d51;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0.3rem;
  text-decoration: none;
  cursor: pointer;
}

.profiletab:hover {
  color: #9a9ca0;
}

.profilebtn:hover {
  background-color: #474e62 !important;
}
 
.profilebtn:hover { 
  border-color: #353d51 !important;
} 

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #f4f0f0;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #f4f0f0;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #1f232c;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  overflow: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #1f232c;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.refbal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
}

.textbal {
  user-select: none;
}

.textref {
  display: flex;
  width: 100%;
  height: 40px;
  font-size: 13px;
  margin: 10px 0px;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
}

.btnbal {
  margin: 20px 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  border-radius: 5px;
  height: 30px;
  background:linear-gradient(0deg, rgb(66, 141, 255) 0%, rgb(0, 180, 255) 100%);
  color: #ffffff;
}

.btnbal:hover { 
 opacity: 0.7;
}

.copyBtn {
 
  width: 200px;
  height: 35px; 
  background: linear-gradient(0deg, rgb(66 141 255)  0%, rgb(0, 180, 255) 100%) 
}

.ant-popover { 
  width: 360px; 
  
}

.ant-popover-title { 
  color: #428dff !important; 
}

.ant-qrcode {
  margin: 16px;
}




.arrowselect_container{ 
  /* padding-left: 3%; */
  display: flex;
  align-items: center;
  flex-direction: column;
  }

.arrow_container{width: 90%;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 8px 0px }
.arrow_cross{align-items:center;display:flex;flex-direction:row;width:100%;height:50px;}
.arrow_rotate{transform:rotate(45deg);margin-right:8px}
.arrow_title{
 
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, Roboto, Ubuntu, Droid Sans, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    color: #353d51;
    font-weight: 600;
    font-size: 16px; 
    text-decoration: none;
    cursor: pointer;
}
.mobileArrowTitle{font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  /* letter-spacing: 1.15px; */
  color: #212629;
  font-family: inherit;}
  .mobileArrow_rotate{transform:rotate(45deg);margin-right:10px}
  .mobileAnswer_description {
    margin: 6px 0 20px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: .84px;
    color: #363636;
    font-family: HelveticaNeue,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}
.arrow_description{margin:21px 0 50px;font-family:Montserrat;font-size:17px;font-weight:400;font-stretch:normal;font-style:normal;line-height:1.67;letter-spacing:.84px;color:#363636;font-family: HelveticaNeue,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol}
.on_arrow{animation:rotateArrow .5s ease forwards}
.off_arrow{animation:antirotateArrow .5s ease forwards}
@keyframes rotateArrow{from{transform:rotate(270deg)}to{transform:rotate(360deg)}}
@keyframes antirotateArrow{from{transform:rotate(90deg)}to{transform:rotate(270deg)}}