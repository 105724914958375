.pageLocationBlockWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
  background-color: #f8f9fc;
}
 
.mobilePageLocationBlockWrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: auto;
  align-items: center;
  justify-content: center;
  margin: 40px 0px;
  flex-wrap: wrap;
}

.pageLocationContainer {
  display: flex;
  width: 90%;
  height: 700px;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  margin: 40px 8px; 
  padding: 40px;
  box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);
}
  
.mobilePageLocationContainer {
  display: flex;
  width: 90%;
  min-height: 300px;
  background: #F2EFE9;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  padding-top: 41px;
  border-radius: 30px;
  margin: 40px 8px;
  box-shadow: 1px 1px 12px rgb(0 0 0 / 20%);
}
 
.locationContainerLine {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.locationTitleGrave {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 30%;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 600;
  font-size: 25px;
  color: #292929; 
}

.locationGrave {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 50%;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 400;
  padding-top: 6px;
  font-size: 25px;
  color: #292929; 
}

.locationContainerLine {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20%;
  border-bottom: 1px solid #c0c9da; 
}

.locationMapContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 20%; 
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 600;
  font-size: 25px;
  color: #292929; 
}

.mapContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; 
  height: 60%;  
}

.location {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 400;
  font-size: 20px;
  color: #292929; 
}

.locationTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 50%;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 600;
  font-size: 25px;
  color: #292929; 
}

.iframeMap {
  width: 700px;
  height: 350px;
}