.mypage_screen {
  background-color: #ffffff; 
} 
.mypage_wrapper {
  width: 100%; 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 135px;
  flex-wrap: wrap;
} 

 .miniPageContainer {
    display: grid;
    width: 90%;
    margin-top:60px;
    min-height: 700px;
    background: #ffffff; 
    flex-direction: column;
    border-radius: 4px; 
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);
 }

 .mobileMiniPageContainer {
    display: grid;
    width: 90%;
    margin-top:60px;
    /* min-height: 700px; */
    background: #ffffff; 
    flex-direction: column;
    border-radius: 4px; 
    grid-gap: 1rem;
    /* grid-template-columns: 1fr 1fr; */
    box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);
 }