.examplePageContainer {
  display: flex;
  width: 90%;
  min-height: 650px;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 80px;
  margin: 40px 8px; 
  box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);
}
  
.mobileExamplePageContainer {
  display: flex;
  width: 90%;
  min-height: 300px;
  background: #ffffff;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  padding-top: 41px;
  border-radius: 30px;
  margin: 40px 8px;
  box-shadow: 1px 1px 12px rgb(0 0 0 / 20%);
}

.photoContainer {
  display: flex;
  width: 70%;
  height: auto;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;
}

.mobilePhotoContainer {
  display: flex;
  width: 90%;
  height: auto;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;
}

.photo {
  width: 100%;
  height: 100%;  
}

.epityContainer {
  position: relative;
}

.quotr {
  width: 12px;
  height: 12px;  
  position: absolute;
  top: 20px;
  left: 5%;
}

.mobileQuotr {
  width: 12px;
  height: 12px;  
  position: absolute;
  top: 20px;
  left: 5%;
}

.quotl {
  width: 12px;
  height: 12px;  
  position: absolute;
  bottom: 20px;
  right: 5%;
}

.mobileQuotl {
  width: 12px;
  height: 12px;  
  position: absolute;
  bottom: 20px;
  right: 5%;
}

.fioinfo {
  display: flex; 
  text-transform: uppercase;
  font-size: 37px;
  align-items: center;
  justify-content: center; 
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 400;
  color: #353d51; 
  margin-bottom: 50px;  
}

.mobileFioinfo {
  display: flex;
  text-transform: uppercase;
  font-size: 17px;
  align-items: center;
  justify-content: center;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 700;
  color: #353d51;
  margin-top: 30px;
  text-align: center;
    line-height: 1.5;
}

.epity { 
  font-weight: 300;
  line-height: 25px;
  color: #303030; 
  font-size: 17px;
  font-family: sans-serif;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: auto; 
  padding: 40px 290px;

  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  text-align: center;  
  word-wrap: break-word;
}

.mobileEpity {
  display: flex;
  font-weight: 300;
  line-height: 25px;
  color: #303030; 
  font-size: 15px;  
  box-sizing: border-box;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;

  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  text-align: center;  
  word-wrap: break-word;
}
 