.pagePhotoBlockWrapper {
  display: flex;
  width: 100%;
  /* height: 1200px; */
  align-items: center;
  justify-content: center;
  background-color: rgb(112, 109, 109);
  flex-direction: column;
  background-color: #f8f9fc;
}
 
.mobilePagePhotoBlockWrapper {
   display: flex;
  width: 100%;
  /* height: 1200px; */
  align-items: center;
  justify-content: center;
  background-color: rgb(112, 109, 109);
  flex-direction: column;
  background-color: #f8f9fc;
}

.pagePhotoContainer {
  display: flex;
  width: 90%;
  padding: 40px;
  /* height: 700px; */
  background: #ffffff30;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  margin: 40px 8px; 
  box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);
}
  
.photoWraper {
  display: flex;
  border: 1px solid black;
  width: 300px;
  height: 300px; 
}
  
.photo {
  display: flex;
  width: 100%;
  height: 100%; 
}

.imageGalleryWrapper {
  display: flex; 
  height: 100%; 
  margin: 0 auto;
  width: auto;
  /* width: 65%; */
  position: relative;
}

.mobileImageGalleryWrapper {
  display: flex; 
  height: 100%; 
  margin: 0 auto;
  /* width: 90%; */
  position: relative;
}
  
.image-gallery-left-nav, .image-gallery-right-nav {
 padding: 0px !important
}