.input_number_component_wrapper {
  display: flex;
  width: 10%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  padding-left: 20px;
}
.mobile_input_number_component_wrapper {
  display: flex;
  width: 30%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  padding-left: 20px;
}

.input_number_component_item {
  display: flex;
  width: 89%;
  height: 35px;
  align-items: center; 
  border-radius: 5px;
  padding-left: 20px;
  border-radius: 6px;
  border: none;
  padding-left: 8px;
  color: #4a5aaf;
  box-shadow: 1px 1px 5px #00000029;
}