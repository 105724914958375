@import "~react-image-gallery/styles/css/image-gallery.css";
.App {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
}

nav a {
  text-decoration: none !important;
  text-align: left;
}

#root {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
}

.container {
  max-width: 1248px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
}

main {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  background-color: white;
}

.colorFon_0 {
  color: rgb(255, 255, 255);
}

.colorFon_1 {
  color: rgb(255, 255, 255);
}

.colorFon_2 {
  color: rgb(255, 255, 255);
}

.colorFon_none {
  color: #494949;
}
