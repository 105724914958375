.answer_container{width:90%;padding-left: 3%}.answer_cross{align-items:center;display:flex;flex-direction:row;width:100%;height:50px}.cross_rotate{transform:rotate(45deg);margin-right:30px}
.answer_title{font-size:24px;font-weight:600;font-stretch:normal;font-style:normal;line-height:1.17;letter-spacing:1.45px;color:#212629;font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.mobileAnswerTitle{font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 1.15px;
  color: #212629;
  font-family: 'Montserrat-Bold';}
  .mobileCross_rotate{transform:rotate(45deg);margin-right:30px}
  .mobileAnswer_description {
    margin: 6px 0 20px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: .84px;
    color: #363636;
    font-family: HelveticaNeue,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}
.answer_description{margin:21px 0 50px;font-family:Montserrat;font-size:17px;font-weight:400;font-stretch:normal;font-style:normal;line-height:1.67;letter-spacing:.84px;color:#363636;font-family: HelveticaNeue,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol}.on_crooss{animation:rotateCross .5s ease forwards}.off_crooss{animation:antirotateCross .5s ease forwards}@keyframes rotateCross{from{transform:rotate(45deg)}to{transform:rotate(90deg)}}@keyframes antirotateCross{from{transform:rotate(90deg)}to{transform:rotate(45deg)}}



 
.arrow_cross{align-items:center;display:flex;flex-direction:row;width:100%;height:50px}
.arrow_rotate{transform:rotate(45deg);margin-right:30px}

  .mobileArrow_rotate{ transform:rotate(45deg);margin-right:10px }
  .mobileAnswer_description {
    margin: 6px 0 20px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: .84px;
    color: #363636;
    font-family: HelveticaNeue,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}
.arrow_description{margin:21px 0 50px;font-family:Montserrat;font-size:17px;font-weight:400;font-stretch:normal;font-style:normal;line-height:1.67;letter-spacing:.84px;color:#363636;font-family: HelveticaNeue,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol}
.on_arrow{animation:rotateArrow .5s ease forwards}
.off_arrow{animation:antirotateCross .5s ease forwards}
@keyframes rotateArrow{from{transform:rotate(45deg)}to{transform:rotate(90deg)}}
@keyframes antirotateCross{from{transform:rotate(90deg)}to{transform:rotate(45deg)}}