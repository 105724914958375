.page_wrapper {
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding-top: 80px; */
  flex-wrap: wrap;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
} 

.mobile_page_wrapper {
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding-top: 100px; */
  flex-wrap: wrap;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* background-position-y: -40px; */
} 

 .backtransparent {
  padding: 30px;
  background-color: #ffffff99;
 }