  .user_yes_screen {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  }

  .user_yes_wrapper {
  display: flex;
  width: 90%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 110px;
  padding: 80px;
  box-shadow: 1px 0px 12px rgb(0 0 0 / 20%);
  border-radius: 12px;
  line-height: 1.6
  }

  .mobile_user_yes_wrapper {
  display: flex;
  width: 90%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 110px;
  padding: 10px;
  box-shadow: 1px 0px 12px rgb(0 0 0 / 20%);
  border-radius: 12px;
  line-height: 1.4
  }

  .ofer_screen {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    box-sizing: border-box;
  }

  .ofer_wrapper {
    display: flex;
    width: 90%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 110px;
    padding: 80px;
    box-shadow: 1px 0px 12px rgb(0 0 0 / 20%);
    border-radius: 12px;
    line-height: 1.6
  }

  .mobileOfer_wrapper {
    display: flex;
    width: 90%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 110px;
    padding: 10px;
    box-shadow: 1px 0px 12px rgb(0 0 0 / 20%);
    border-radius: 12px;
    line-height: 1.4
  }

  .politics_screen {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    box-sizing: border-box;
  }

  .politics_wrapper {
    display: flex;
    width: 90%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 110px;
    padding: 80px;
    box-shadow: 1px 0px 12px rgb(0 0 0 / 20%);
    border-radius: 12px;
    line-height: 1.6
  }

  .mobilePolitics_wrapper {
    display: flex;
    width: 96%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 110px;
    padding: 10px;
    box-shadow: 1px 0px 12px rgb(0 0 0 / 20%);
    border-radius: 12px;
    line-height: 1.4
  }

  ol {
    /* убираем стандартную нумерацию */
    list-style: none;
    /* Идентифицируем счетчик и даем ему имя li. Значение счетчика не указано - по умолчанию оно равно 0 */
    counter-reset: li;
  }
  ol li:before {
    /* Определяем элемент, который будет нумероваться — li. Псевдоэлемент before указывает, что содержимое, вставляемое при помощи свойства content, будет располагаться перед пунктами списка. Здесь же устанавливается значение приращения счетчика (по умолчанию равно 1). */
    counter-increment: li;
    /* С помощью свойства content выводится номер пункта списка. counters() означает, что генерируемый текст представляет собой значения всех счетчиков с таким именем. Точка в кавычках добавляет разделяющую точку между цифрами, а точка с пробелом добавляется перед содержимым каждого пункта списка */
    content: counters(li, ".") ". ";
  }

  .mobileFont_1 {
  font-size: 14px; 
  }

  .mobilePadding_0 {
  padding: 0px;
  width: 98%;
  }

  .marginTop {
    margin-top: 30px;
  }