.pageQrBlockWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
  background-color: #f8f9fc;
}
 
.mobilePageQrBlockWrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: auto;
  align-items: center;
  justify-content: center;
  margin: 40px 0px;
  flex-wrap: wrap;
}

.mobilePageQrContainer {
  display: flex;
  width: 90%;
  padding: 20px;
  /* height: 700px; */
  background: #ffffff;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  margin: 40px 8px; 
  box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);
}

.pageQrContainer {
  display: flex;
  /* width: 90%; */
  padding: 40px;
  /* height: 700px; */
  background: #ffffff;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  margin: 40px 8px; 
  box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);
}
  
.qrWraper {
  display: flex;
  border: 1px solid black;
  width: 300px;
  height: 300px; 
}
  
.qr {
  display: flex;
  width: 100%;
  height: 100%; 
}
  
.btnMobileContainer {
  display: flex;
  width: 100%;
  height: 100%; 
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
  
.qrTitle {
  padding: 20px;
  font-size: 25px; 
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 500;
  color: #353d51;
}
 
.rws-close {
  background: #0088cc;
  color: #ffffff;
  cursor: pointer;
  padding: 0.75rem 0px;
  display: block;
  width: 100%;
  border: 0;
  font-size: 1rem;
}
 
.rws-icons {
  box-sizing: border-box;
}