.wrapperLKButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer; 
  outline: none;
  border: none;
  overflow: hidden;   
  color: #fff;
  border: 1px solid #fff;
  background: transparent; 
  min-width: 150px;
}

.textLKButton {
  font-size: 15px;
  font-weight: 600;
  font-family: system-ui; 
  user-select: none;
}

.wrapperLKButton:hover { 
  color: #353d51 !important;
    background: #fff;
}