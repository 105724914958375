.pageTitleBlockWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
  /* background-color: #f8f9fc; */
}
 

.mobilePageTitleWrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: auto;
  align-items: center;
  justify-content: center;
  margin: 40px 0px;
  flex-wrap: wrap;
}

.pageTitlekBtnWrapper {
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;
}

.pageTitleContainer {
  display: flex;
  width: 90%;
  min-height: 650px;
  background:#ffffff30;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  margin: 40px 8px; 
  box-shadow: 1px 1px 6px rgb(0 0 0 / 20%);
}
  
.mobilePageTitleContainer {
  display: flex;
  width: 90%;
  min-height: 300px;
  background: #F2EFE9;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  padding-top: 41px;
  border-radius: 30px;
  margin: 40px 8px;
  box-shadow: 1px 1px 12px rgb(0 0 0 / 20%);
}

.pageTitlePhotoContainer {
  display: flex;
  width: 50%;
  height: auto;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 70px auto;
  overflow: hidden;
}

.pageTitlePhoto {
  width: 100%;
  height: 100%;  
}

.pageTitleEpityContainer {
  position: relative;
  width: 60%;
  margin: 0px auto;
}

.pageTitleQuotr {
  width: 12px;
  height: 12px;  
  position: absolute;
  top: 10%;
  left: 2%;
}

.pageTitleQuotl {
  width: 12px;
  height: 12px;  
  position: absolute;
  bottom: 10%;
  right: 2%;
}

.pageTitleFio {
  display: flex;
  text-transform: uppercase;
  font-size: 50px;
  align-items: center;
  justify-content: center;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 500;
  color: #353d51;
  margin: 70px 0px;  
}

.pageTitleEpity {
  display: flex;
  font-weight: 400;
  line-height: 25px;
  color: #303030;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-family: sans-serif; 
  box-sizing: border-box;
  width: 100%;
  height: auto; 
  padding: 40px;
  margin: 70px auto;
  /* background-color: #c0c9da69; */
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  text-align: center;  
  word-wrap: break-word;
  white-space: pre-wrap;
}
 
.errorDateTxt {
    display: flex;
    text-transform: uppercase;
    font-size: 25px;
    align-items: center;
    justify-content: center;
    font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
    font-weight: 500;
    color: #353d51;
    margin: 40px 0px;
}


.pageTitleBlockContainer{
  display: flex;
    flex-direction: column;
    align-items: center;
}