.wrapperFooterLinkButton { 
  
  outline: none;
  border: none;
  overflow: hidden;
  border-radius: 4px;
  background: rgb(55 62 78);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer; 

}
.mobileWrapperFooterLinkButton { 
  
  outline: none;
  border: none;
  overflow: hidden;
  border-radius: 4px;
  background: rgb(55 62 78);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;  

}

.textFooterLinkButton {
  font-size: 15px;
  font-weight: 600;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  color: #f8f5f0;
  user-select: none;
}

.mobileTextFooterLinkButton {
  font-size: 13px;
  font-weight: 600;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  color: #f8f5f0;
  user-select: none;
}

.wrapperFooterLinkButton:hover {
  /* opacity: .7; */
  background:#6f788e !important;
}