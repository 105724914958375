
.bioSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
}
 

.bioInnerTitle {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  color: #292929;
  font-family: Montserrat,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 600; 
  font-size: 30px;
}

.bioInnerDescr {
  display: flex;
  width: 100%; 
  font-family: HelveticaNeue,-apple-system,BlinkMacSystemFont,Roboto,Ubuntu,Droid Sans,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.2;  
  text-align: left;
  word-break: break-word;
  white-space: pre-wrap;
  color: #535866; 
}

.bioPhotoSection {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 400px;
  padding-left: 70px;
}

.mobile_bioPhotoSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  /* height: 400px; */
  padding-left: 0px !important;
}
 

.bioSectionContainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;   

  border-bottom: 1px solid gray;
  margin-bottom: 40px;
}
  
.bioSectionContainer:last-child {
  border: none;
}

.clearBtnCreate {
  position: absolute;
  top: 10%;
  right: 10%;
  color: #1677ff;
  cursor: pointer;
}

.clearBtnCreate:hover { 
  color: #ff1616; 
}
