
.loader_wrapper {
  display: flex;
    justify-content: center;
    align-items: center;
    background-color: #01142c80;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
}
